<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Copy</p>
      <v-spacer></v-spacer>
      <dev-menu></dev-menu>
    </div>

    <v-card class="container">
      <v-card-text>
        <div v-for="n in textareasNumber" class="d-flex flex-row mt-6">
          <v-textarea
            outlined
            v-model="textareas[n]"
            label=""
            value=""
          ></v-textarea>
          <v-btn @click="copy" :data-index="n" color="primary" class="mx-4">
            Copy
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DevMenu from "@/views/menus/DevMenu.vue";
export default {
  name: "CopyPaste",
  components: { DevMenu },
  data() {
    return {
      textareasNumber: 3,
      textareas: {},
    };
  },
  methods: {
    copy(e) {
      let selectedIndex = e.currentTarget.getAttribute("data-index");
      let inputValue = this.textareas[selectedIndex];
      navigator.clipboard.writeText(inputValue).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
  mounted() {},
  computed: {
    // myComputeFunction() {},
  },
  watch: {
    // isOpenCreateCronDialog(visible) {
    //   if (visible) {
    //     this.identifier = "";
    //     this.$refs.FormCron.resetValidation();
    //   }
    // },
  },
};
</script>
